export const translations = {
  ja: {
    title: 'PrePaid SIM for Japan',
    subtitle: 'すぐに使える、自由なコネクション',
    buyNow: '今すぐ購入する',
    physicalAndESim: '物理SIM・eSIM対応 / SMS認証対応 / eKYC不要のプリペイドSIM',
    
    campaign: {
      title: '🎉 期間限定キャンペーン実施中 🎉',
      description: '初月無料！購入した月の翌月末まで利用可能',
      notice: '今がチャンス！この機会をお見逃しなく🎉',
      banner: '🎉 今なら初月無料！翌月末まで使える特別キャンペーン中 🎉'
    },

    features: {
      title: 'サービスの特徴',
      items: [
        {
          title: '物理SIM & eSIM',
          description: '最新のeSIMテクノロジーと従来の物理SIM、両方に対応。eSIMなら即利用可能です。'
        },
        {
          title: 'SMS認証対応',
          description: '各種アプリやサービスのSMS認証もOK。070/080/090から始まる日本の電話番号付き。'
        },
        {
          title: 'eKYC不要',
          description: '面倒な本人確認手続き不要。すぐに利用開始できる簡単・スピーディなサービスです。'
        }
      ]
    },

    reasons: {
      title: '選ばれる4つの理由',
      items: [
        {
          title: 'DoCoMo回線で日本全国どこでも快適',
          description: '高速データ通信で都市から地方まで、どこでも繋がる安心感'
        },
        {
          title: 'PayPay・コンビニ払い可能',
          description: 'クレジットカードがなくても、お近くのコンビニで簡単に支払い可能'
        },
        {
          title: 'クレカ・銀行口座の登録不要',
          description: '個人情報の登録を最小限に抑え、安心してご利用いただけます'
        },
        {
          title: '買ってすぐ使える',
          description: 'eSIMなら購入後すぐに利用開始可能。一時帰国者や長期滞在にもすぐに対応'
        }
      ]
    },

    pricing: {
      title: '料金プラン',
      headers: {
        data: 'データ容量',
        initial: '初期費用（データ専用）',
        extension: '1ヶ月延長料金'
      },
      notice: {
        lowSpeed: '※ 常時200Kbpsプランは低速ですが容量制限なしで使えます。',
        smsOption: '※ SMSオプションは各プランに追加可能です。延長時のSMSオプション料金は無料となります。'
      },
      plans: [
        { data: "データ通信無し", initialPrice: "詳しくはお問い合わせください。", extensionPrice: "1,000円" },
        { data: "常時200Kbps（低速）", initialPrice: "3,180円", extensionPrice: "1,480円" },
        { data: "1GB", initialPrice: "3,680円", extensionPrice: "1,680円" },
        { data: "3GB", initialPrice: "4,280円", extensionPrice: "2,280円" },
        { data: "5GB", initialPrice: "4,980円", extensionPrice: "2,980円" },
        { data: "10GB", initialPrice: "5,980円", extensionPrice: "3,680円" },
        { data: "20GB", initialPrice: "6,980円", extensionPrice: "4,980円" },
        { data: "SMSオプション<br /><span style='font-size: xx-small;'>※受信のみ対応、送信した場合回線停止</span>", initialPrice: "300円", extensionPrice: "0円" },
      ]
    },

    purchaseFlow: {
      title: '購入から利用開始までの流れ',
      steps: [
        {
          title: 'プラン選択',
          description: 'ニーズに合わせて最適なデータプランを選びます。'
        },
        {
          title: 'お支払い',
          description: 'PayPayまたはコンビニ払いで簡単決済。'
        },
        {
          title: 'SIM受け取り',
          description: '物理SIMは郵送、eSIMは即時ダウンロード。'
        },
        {
          title: '設定・接続',
          description: 'APN設定を行い、すぐにインターネットに接続。'
        }
      ]
    },

    usage: {
      title: '日本での利用について',
      description: [
        'PrePaid SIM for Japanでは、日本国内での利用に最適化されたプリペイドSIMサービスです。DoCoMo回線を使用しているため、国内の主要都から地方まで、広囲で高速なネットワークを提供しています。',
        '日本の法律では、プリペイドSIMの販売にeKYC（本人確認）は必要ありません。そのため、私たちは煩わしい手続きなしで、すぐに利用開始できるサービスを提供しています。',
        'クレジットカードや銀行口座をお持ちでない方も安心してご利用いただけます。PayPayやコンビニ払いに対応しているため、近くのコンビニエンスストアで簡単に支払いが可能です。海外からの旅行者の方々も便利なサービスです。',
        'eSIMをご利用の場合、購入後すぐに利用開始が可能です。旅行者の方も、長期滞在の方も、手軽に日本での通信環境を整えることができ、到着後すぐにインターネットに接続できます。'
      ],
      campaign: 'さらに、現在実施中のキャンペーンは初月無料！購入した月の翌月末まで利用可能です。このチャンスをお見逃しなく！'
    },

    navigation: {
      mypage: 'マイページはこちら',
      contact: 'お問い合わせ',
      purchase: '購入する',
      contactOptions: {
        line: '公式LINE',
        telegram: 'Telegram',
        form: 'メール'
      }
    },

    footer: {
      copyright: '© 2023 プリペイドSIM屋さん. All rights reserved.'
    },

    startNow: 'さあ、始めよう',

    faq: {
      title: 'よくある質問',
      items: [
        {
            question: '購入時に本人確認は必要ですか？',
            answer: 'いいえ、本人確認手続き(eKYC)は不要です。'
        },
        {
            question: '個人情報はどこまで保存されますか？',
            answer: '当店ではプライバシー保護を尊重しており、お客様の個人情報はなるべく最小限に保存されます。お支払い時に使用したメールアドレス及びPayPayリンクのみ保存されます。'
        },
        {
          question: 'プリペイドSIMの有効期限はありますか？',
          answer: '初回購入時は購入月の翌月末まで利用可能です。その後は1ヶ月単位で延長が可能です。現在キャンペーン中につき、初月（購入月）は無料でご利用いただけます。'
        },
        {
          question: '通信速度はどのくらいですか？',
          answer: 'DoCoMo回線を使用しており、4G/LTE対応で下り最大150Mbpsの高速通信が可能です。ただし、ベストエフォートでの提供となりますので、通信環境や混雑状況により実際の速度は異なる場合があります。'
        },
        {
            question: '通信容量を使い切った場合どうなりますか？',
            answer: '通信容量を使い切った場合は、月末まで200Kbpsの低速通信となります。翌月1日に通信容量がリセットされます。'
        },
        {
            question: '日本以外（海外）でも使えますか？',
            answer: 'いいえ、現時点では日本国内でのみご利用いただけます。'
        },
        {
          question: '日本国内であればどこでも使えますか？',
          answer: 'はい、DoCoMoの電波が届く場所であれば離島を含む日本全国どこでもご利用いただけます。'
        },
        {
          question: '契約期間の縛りはありますか？',
          answer: 'いいえ、契約期間の縛りはありません。利用期間を延長しない場合は使い捨ても可能です。'
        },
        {
          question: 'SMSの送信は可能ですか？',
          answer: 'いいえ、SMS送信はできません。現在のところ受信のみ対応しており、送信した場合はシステム検知により回線停止となります。回線が停止された場合は利用期間内であれば回線復帰手数料3,300円をお支払いいただくことで回線復帰が可能です。'
        },
        {
            question: '音声通話は可能ですか？',
            answer: 'いいえ、音声通話はできません。LINEアプリや050IP電話などのデータ通信を利用した通話方法をご検討ください。'
        },
        {
          question: '購入が完了してもメール通知が来ないのはなぜですか？',
          answer: 'メールアドレスの入力ミスがないかご確認ください。また、メールが迷惑メールフォルダに入っていないかご確認ください。もしeSIMのアクティベート情報が届かない場合は、サポートまでお問い合わせください。'
        },
        {
            question: '一度にまとめて購入したいのですが可能ですか？',
            answer: 'はい、お気軽にお問い合わせください。業販価格をご用意しております。'
        },
        {
          question: 'データ通信無しのプランはどういう意味ですか？',
          answer: 'データ通信無しのプランは、SMS受信のみ可能でデータ通信が出来ないことを意味します。そのため、電話番号の維持だけしたい方はこちらをご利用ください。'
        },
        {
          question: '対応しているEIDのパターンは何ですか？',
          answer: '対応しているEIDは以下の4パターンのみです：\n\n- 89049032～\n- 89033024～\n- 89043051～\n- 89043052～\n\nこれらのパターン以外のEIDには対応しておりませんので、ご注意ください。'
        }
      ]
    }
  },
  
  en: {
    title: 'PrePaid SIM for Japan',
    subtitle: 'Free Connection Available in Japan',
    buyNow: 'Buy Now',
    physicalAndESim: 'Physical SIM & eSIM / SMS Authentication / No eKYC Required Prepaid SIM',
    
    campaign: {
      title: '🎉 Limited Time Campaign 🎉',
      description: 'First month free! Valid until the end of next month from purchase',
      notice: "Don't miss this opportunity! 🎉",
      banner: '🎉 Get the first month free! Special campaign valid until the end of next month 🎉'
    },

    features: {
      title: 'Features of Our Service',
      items: [
        {
          title: 'Physical SIM & eSIM',
          description: 'Supports the latest eSIM technology as well as traditional physical SIMs. eSIMs are available for immediate use.'
        },
        {
          title: 'SMS Authentication',
          description: 'Supports SMS authentication for various apps and services. Comes with a Japanese phone number starting with 070/080/090.'
        },
        {
          title: 'No eKYC Required',
          description: 'No cumbersome identity verification processes. Our service is easy and speedy to start using immediately.'
        }
      ]
    },

    reasons: {
      title: '4 Reasons to Choose Us',
      items: [
        {
          title: 'Reliable DoCoMo Network Nationwide',
          description: 'High-speed data communication ensures reliable connections from cities to rural areas.'
        },
        {
          title: 'PayPay & Convenience Store Payments',
          description: 'Easy payments at nearby convenience stores even without a credit card.'
        },
        {
          title: 'No Credit Card or Bank Account Registration',
          description: 'Minimizes personal information registration, allowing you to use our service with peace of mind.'
        },
        {
          title: 'Ready to Use Immediately',
          description: 'With eSIM, you can start using it right after purchase. Ideal for short-term visitors and long-term residents.'
        }
      ]
    },

    pricing: {
      title: 'Pricing Plans',
      headers: {
        data: 'Data Volume',
        initial: 'Initial Cost (Data Only)',
        extension: '1-Month Extension Fee'
      },
      notice: {
        lowSpeed: '※ The always-active 200Kbps plan is slow but has no data limit.',
        smsOption: '※ SMS options can be added to each plan. SMS option fees are free upon extension.'
      },
      plans: [
        { data: "No Data Communication", initialPrice: "Coming soon", extensionPrice: "Coming soon" },
        { data: "Always-On 200Kbps (Low Speed)", initialPrice: "¥3,180", extensionPrice: "¥1,480" },
        { data: "1GB", initialPrice: "¥3,680", extensionPrice: "¥1,680" },
        { data: "3GB", initialPrice: "¥4,280", extensionPrice: "¥2,280" },
        { data: "5GB", initialPrice: "¥4,980", extensionPrice: "¥2,980" },
        { data: "10GB", initialPrice: "¥5,980", extensionPrice: "¥3,680" },
        { data: "20GB", initialPrice: "¥6,980", extensionPrice: "¥4,980" },
        { data: "SMS Option<br /><span style='font-size: xx-small;'>※Receive only, service will be suspended if sent</span>", initialPrice: "¥300", extensionPrice: "¥0" },
      ]
    },

    purchaseFlow: {
      title: 'Purchase and Activation Flow',
      steps: [
        {
          title: 'Select a Plan',
          description: 'Choose the optimal data plan according to your needs.'
        },
        {
          title: 'Payment',
          description: 'Easy payment via PayPay or convenience store.'
        },
        {
          title: 'Receive SIM',
          description: 'Physical SIMs are mailed, eSIMs are available for instant download.'
        },
        {
          title: 'Setup & Connect',
          description: 'Configure APN settings and connect to the internet immediately.'
        }
      ]
    },

    usage: {
      title: 'Using in Japan',
      description: [
        'PrePaid SIM Shop offers prepaid SIM services optimized for use within Japan. Utilizing the DoCoMo network, we provide a wide and high-speed network coverage from major cities to rural areas.',
        'Under Japanese law, eKYC (identity verification) is not required for selling prepaid SIMs. Therefore, we offer a service that can be used immediately without tedious procedures.',
        'Those without credit cards or bank accounts can use our service with peace of mind. With support for PayPay and convenience store payments, you can easily pay at nearby convenience stores. It is also a convenient service for travelers from overseas.',
        'If you use an eSIM, you can start using it immediately after purchase. Both travelers and long-term residents can easily set up their communication environment in Japan and connect to the internet immediately upon arrival.'
      ],
      campaign: 'Additionally, our current campaign offers the first month free! Valid until the end of the month following your purchase. Don’t miss this chance!'
    },

    navigation: {
      mypage: 'Go to My Page',
      contact: 'Contact Us',
      purchase: 'Purchase',
      contactOptions: {
        line: 'LINE',
        telegram: 'Telegram',
        form: 'Mail'
      }
    },

    footer: {
      copyright: '© 2023 PrePaid SIM Shop. All rights reserved.'
    },

    startNow: 'Let\'s Get Started',

    faq: {
      title: 'FAQ',
      items: [
        {
          question: 'Is identity verification required at purchase?',
          answer: 'No, identity verification (eKYC) is not required.'
        },
        {
          question: 'How much personal information is stored?',
          answer: 'We respect privacy protection and store minimal personal information. Only the email address used for payment and PayPay link are stored.'
        },
        {
          question: 'Does the prepaid SIM have an expiration date?',
          answer: 'For the initial purchase, it can be used until the end of the following month. After that, it can be extended on a monthly basis. Currently, during our campaign, the first month (purchase month) is free.'
        },
        {
          question: 'What is the communication speed?',
          answer: 'We use the DoCoMo network, supporting 4G/LTE with a maximum download speed of 150Mbps. However, since it is provided on a best-effort basis, actual speeds may vary depending on network conditions and congestion.'
        },
        {
          question: 'What happens when I use up all my data?',
          answer: 'When you use up all your data, the speed will be reduced to 200Kbps until the end of the month. Data allowance resets on the 1st day of the following month.'
        },
        {
          question: 'Can I use this SIM outside Japan?',
          answer: 'No, currently it can only be used within Japan.'
        },
        {
          question: 'Can I use it anywhere in Japan?',
          answer: 'Yes, as long as it is within areas covered by the DoCoMo network, you can use it anywhere in Japan, including remote islands.'
        },
        {
          question: 'Is there a contract period commitment?',
          answer: 'No, there is no contract period commitment. If you do not extend the usage period, you can dispose of it.'
        },
        {
          question: 'Can I send SMS messages?',
          answer: 'No, SMS sending is not available. Currently, only receiving is supported, and if you attempt to send, your line will be suspended by system detection. If the line is suspended, you can restore it by paying a restoration fee of ¥3,300 within the usage period.'
        },
        {
          question: 'Are voice calls possible?',
          answer: 'No, voice calls are not available. Please consider using data-based calling methods such as the LINE app or 050 IP phones.'
        },
        {
          question: 'Why haven’t I received an email notification after purchase?',
          answer: 'Please check for any errors in your email address input. Also, check if the email is in your spam folder. If you haven’t received the eSIM activation information, please contact support.'
        },
        {
          question: 'Can I make a bulk purchase?',
          answer: 'Yes, please feel free to contact us. We have wholesale pricing available.'
        },
        {
          question: 'What does the "No Data Communication" plan mean?',
          answer: 'The No Data Communication plan means you can only receive SMS messages and cannot use data communication. This is suitable for those who only want to maintain a phone number.'
        },
        {
          question: 'What are the supported EID patterns?',
          answer: 'Only the following four EID patterns are supported:\n\n- 89049032～\n- 89033024～\n- 89043051～\n- 89043052～\n\nPlease note that EIDs outside of these patterns are not supported.'
        }
      ]
    }
  },

  zh: {
    title: 'PrePaid SIM for Japan',
    subtitle: '在日本可用的自由连接',
    buyNow: '立即购买',
    physicalAndESim: '实体SIM卡和eSIM / 支持短信验证 / 无需身份认证的预付费SIM',
    
    campaign: {
      title: '🎉 限时活动中 🎉',
      description: '首月免费！从购买月份起至下月底有效',
      notice: '千载难逢的机会！不要错过🎉',
      banner: '🎉 现在首月免费！特別活动有效期至下月底 🎉'
    },

    features: {
      title: '我们的服务特点',
      items: [
        {
          title: '实体SIM卡 & eSIM',
          description: '支持最新的eSIM技术以及传统的实体SIM卡。eSIM可立即使用。'
        },
        {
          title: '支持短信验证',
          description: '支持各种应用和服务的短信验证。附带以070/080/090开头的日本电话号码。'
        },
        {
          title: '无需eKYC',
          description: '无需繁琐的身份验证过程。我们的服务简单快捷，您可以立即开始使用。'
        }
      ]
    },

    reasons: {
      title: '选择我们的4个理由',
      items: [
        {
          title: '可靠的DoCoMo全国网络',
          description: '高速数据通信确保从城市到乡村的可靠连接。'
        },
        {
          title: '支持PayPay及便利店付款',
          description: '即使没有信用卡，也可以在附近的便利店轻松付款。'
        },
        {
          title: '无需信用卡或银行账户注册',
          description: '最小化个人信息注册，让您安心使用我们的服务。'
        },
        {
          title: '立即可用',
          description: '使用eSIM后，您可以在购买后立即开始使用。非常适合短期访客和长期居住者。'
        }
      ]
    },

    pricing: {
      title: '价格计划',
      headers: {
        data: '数据容量',
        initial: '初始费用（仅数据）',
        extension: '1个月延长费用'
      },
      notice: {
        lowSpeed: '※ 常时200Kbps计划虽速度较慢，但无数据限制。',
        smsOption: '※ 各计划可添加短信选项。延长时短信选项费用免费。'
      },
      plans: [
        { data: "No Data Communication", initialPrice: "Coming soon", extensionPrice: "Coming soon" },
        { data: "常时200Kbps（低速）", initialPrice: "3,180円", extensionPrice: "1,480円" },
        { data: "1GB", initialPrice: "3,680円", extensionPrice: "1,680円" },
        { data: "3GB", initialPrice: "4,280円", extensionPrice: "2,280円" },
        { data: "5GB", initialPrice: "4,980円", extensionPrice: "2,980円" },
        { data: "10GB", initialPrice: "5,980円", extensionPrice: "3,680円" },
        { data: "20GB", initialPrice: "6,980円", extensionPrice: "4,980円" },
        { data: "短信选项<br /><span style='font-size: xx-small;'>※仅支持接收，发送时将停止线路</span>", initialPrice: "300円", extensionPrice: "0円" },
      ]
    },

    purchaseFlow: {
      title: '购买与激活流程',
      steps: [
        {
          title: '选择套餐',
          description: '根据您的需求选择最适合的数据套餐。'
        },
        {
          title: '付款',
          description: '通过PayPay或便利店轻松支付。'
        },
        {
          title: '接收SIM卡',
          description: '实体SIM卡会邮寄，eSIM卡可立即下载。'
        },
        {
          title: '设置与连接',
          description: '配置APN设置，立即连接互联网。'
        }
      ]
    },

    usage: {
      title: '在日本的使用',
      description: [
        '日本预付费SIM店提供优化用于日本国内使用的预付费SIM服务。利用DoCoMo网络，我们提供从主要城市到乡村的广泛且高速的网络覆盖。',
        '根据日本法律，销售预付费SIM卡无需eKYC（身份验证）。因此，我们提供无需繁琐程序即可立即使用的服务。',
        '没有信用卡或银行账户的用户也可以放心使用我们的服务。支持PayPay和便利店付款，您可以轻松在附近的便利店支付。对于来自海外的旅行者也是一个方便的服务。',
        '如果您使用的是eSIM，购买后即可立即开始使用。无论是旅行者还是长期居住者，都可以轻松在日本建立通信环境，并在到达后立即连接互联网。'
      ],
      campaign: '此外，我们当前的活动提供首月免费！有效期至购买后的下月底。不要错过这个机会！'
    },

    navigation: {
      mypage: '前往我的页面',
      contact: '联系我们',
      purchase: '购买',
      contactOptions: {
        line: 'LINE',
        telegram: 'Telegram',
        form: 'Mail'
      }
    },

    footer: {
      copyright: '© 2023 日本预付费SIM店. 版权所有.'
    },

    startNow: '让我们开始吧',

    faq: {
      title: '常见问题',
      items: [
        {
          question: '购买时需要身份验证吗？',
          answer: '不需要，无需进行身份验证（eKYC）。'
        },
        {
          question: '会存储多少个人信息？',
          answer: '我们尊重隐私保护，仅存储最少量的个人信息。只保存支付时使用的电子邮件地址和PayPay链接。'
        },
        {
          question: '预付费SIM卡有有效期吗？',
          answer: '首次购买时可使用至下个月底。之后可以按月延长。目前活动期间，首月（购买月）免费。'
        },
        {
          question: '通信速度如何？',
          answer: '使用DoCoMo网络，支持4G/LTE，最大下载速度可达150Mbps。但由于这是尽力而为的服务，实际速度可能因网络条件和拥堵情况而异。'
        },
        {
          question: '用完所有数据流量后会怎样？',
          answer: '用完所有数据流量后，速度将降至200Kbps直到月底。数据流量将在下个月1日重置。'
        },
        {
          question: '可以在日本以外的地方使用吗？',
          answer: '不可以，目前仅限在日本国内使用。'
        },
        {
          question: '在日本各地都能使用吗？',
          answer: '是的，只要有DoCoMo网络覆盖的地方，包括离岛在内的日本全国各地都可以使用。'
        },
        {
          question: '有合约期限限制吗？',
          answer: '没有，没有合约期限限制。如果不延长使用期限，可以直接废弃。'
        },
        {
          question: '可以发送短信吗？',
          answer: '不可以，短信仅支持接收。如果尝试发送，系统将检测并暂停线路。如果线路被暂停，在使用期限内支付3,300日元的恢复费用后可以恢复使用。'
        },
        {
          question: '可以进行语音通话吗？',
          answer: '不可以，不支持语音通话。请考虑使用LINE应用或050 IP电话等数据通信方式进行通话。'
        },
        {
          question: '购买完成后为什么没有收到邮件通知？',
          answer: '请检查邮箱地址是否输入正确。另外，请检查邮件是否进入垃圾邮件文件夹。如果没有收到eSIM激活信息，请联系客服。'
        },
        {
          question: '可以一次性批量购买吗？',
          answer: '可以，请随时联系我们。我们提供批发价格。'
        },
        {
          question: '"无数据通信"套餐是什么意思？',
          answer: '无数据通信套餐意味着只能接收短信，不能使用数据通信。适合只想保留电话号码的用户。'
        },
        {
          question: '支持哪些EID模式？',
          answer: '仅支持以下四种EID模式：\n\n- 89049032～\n- 89033024～\n- 89043051～\n- 89043052～\n\n请注意，除上述模式外的EID不受支持。'
        }
      ]
    }
  },

  vi: {
    title: 'PrePaid SIM for Japan',
    subtitle: 'Kết nối tự do có sẵn tại Nhật Bản',
    buyNow: 'Mua Ngay',
    physicalAndESim: 'SIM vật lý & eSIM / Hỗ trợ xác thực SMS / Không cần eKYC SIM trả trước',
    
    campaign: {
      title: '🎉 Khuyến mãi giới hạn thời gian 🎉',
      description: 'Tháng đầu tiên miễn phí! Có hiệu lực đến cuối tháng sau khi mua',
      notice: 'Đừng bỏ lỡ cơ hội này! 🎉',
      banner: '🎉 Nhận tháng đầu tiên miễn phí! Chiến dịch đặc biệt có hiệu lực đến cuối tháng sau 🎉'
    },

    features: {
      title: 'Đặc điểm của Dịch vụ',
      items: [
        {
          title: 'SIM vật lý & eSIM',
          description: 'Hỗ trợ công nghệ eSIM mới nhất cũng như SIM vật lý truyền thống. eSIM có sẵn để sử dụng ngay lập tức.'
        },
        {
          title: 'Hỗ trợ xác thực SMS',
          description: 'Hỗ trợ xác thực SMS cho các ứng dụng và dịch vụ khác nhau. Kèm theo số điện thoại Nhật Bản bắt đầu bằng 070/080/090.'
        },
        {
          title: 'Không cần eKYC',
          description: 'Không cần các quy trình xác thực danh tính rườm rà. Dịch vụ của chúng tôi dễ dàng và nhanh chóng để bắt đầu sử dụng ngay lập tức.'
        }
      ]
    },

    reasons: {
      title: '4 Lý Do Chọn Chúng Tôi',
      items: [
        {
          title: 'Mạng DoCoMo đáng tin cậy trên toàn quốc',
          description: 'Kết nối dữ liệu tốc độ cao đảm bảo kết nối ổn định từ các thành phố đến khu vực nông thôn.'
        },
        {
          title: 'Thanh toán PayPay & Cửa hàng tiện lợi',
          description: 'Thanh toán dễ dàng tại các cửa hàng tiện lợi gần bạn ngay cả khi không có thẻ tín dụng.'
        },
        {
          title: 'Không cần đăng ký thẻ tín dụng hoặc tài khoản ngân hàng',
          description: 'Giảm thiểu việc đăng ký thông tin cá nhân, giúp bạn sử dụng dịch vụ của chúng tôi một cách an tâm.'
        },
        {
          title: 'Sẵn sàng sử dụng ngay lập tức',
          description: 'Với eSIM, bạn có thể bắt đầu sử dụng ngay sau khi mua. Lý tưởng cho du khách ngắn hạn và cư dân dài hạn.'
        }
      ]
    },

    pricing: {
      title: 'Gói Giá',
      headers: {
        data: 'Dung lượng dữ liệu',
        initial: 'Chi phí ban đầu (Chỉ dữ liệu)',
        extension: 'Phí gia hạn 1 tháng'
      },
      notice: {
        lowSpeed: '※ Gói 200Kbps luôn hoạt động có tốc độ chậm nhưng không giới hạn dữ liệu.',
        smsOption: '※ Tùy chọn SMS có thể được thêm vào từng gói. Phí tùy chọn SMS sẽ miễn phí khi gia hạn.'
      },
      plans: [
        { data: "No Data Communication", initialPrice: "Coming soon", extensionPrice: "Coming soon" },
        { data: "Luôn hoạt động 200Kbps (Tốc độ thấp)", initialPrice: "3,180円", extensionPrice: "1,480円" },
        { data: "1GB", initialPrice: "3,680円", extensionPrice: "1,680円" },
        { data: "3GB", initialPrice: "4,280円", extensionPrice: "2,280円" },
        { data: "5GB", initialPrice: "4,980円", extensionPrice: "2,980円" },
        { data: "10GB", initialPrice: "5,980円", extensionPrice: "3,680円" },
        { data: "20GB", initialPrice: "6,980円", extensionPrice: "4,980円" },
        { data: "Tùy chọn SMS<br /><span style='font-size: xx-small;'>※Chỉ hỗ trợ nhận, sẽ ngừng dịch vụ nếu gửi</span>", initialPrice: "300円", extensionPrice: "0円" },
        
      ]
    },

    purchaseFlow: {
      title: 'Quy trình Mua và Kích hoạt',
      steps: [
        {
          title: 'Chọn Gói',
          description: 'Chọn gói dữ liệu tối ưu phù hợp với nhu cầu của bạn.'
        },
        {
          title: 'Thanh toán',
          description: 'Thanh toán dễ dàng qua PayPay hoặc cửa hàng tiện lợi.'
        },
        {
          title: 'Nhận SIM',
          description: 'Sim vật lý được gửi qua thư, eSIM có thể tải xuống ngay lập tức.'
        },
        {
          title: 'Cài đặt & Kết nối',
          description: 'Cấu hình thiết lập APN và kết nối internet ngay lập tức.'
        }
      ]
    },

    usage: {
      title: 'Sử dụng tại Nhật Bản',
      description: [
        'Cửa hàng SIM trả trước Nhật Bản cung cấp dịch vụ SIM trả trước được tối ưu hóa cho việc sử dụng trong nước Nhật. Sử dụng mạng DoCoMo, chúng tôi cung cấp phạm vi phủ sóng mạng rộng và tốc độ cao từ các thành phố lớn đến khu vực nông thôn.',
        'Theo luật pháp Nhật Bản, việc bán SIM trả trước không yêu cầu eKYC (xác thực danh tính). Do đó, chúng tôi cung cấp dịch vụ mà bạn có thể sử dụng ngay lập tức mà không cần thủ tục rườm rà.',
        'Người dùng không có thẻ tín dụng hoặc tài khoản ngân hàng cũng có thể sử dụng dịch vụ của chúng tôi một cách yên tâm. Với hỗ trợ thanh toán qua PayPay và cửa hàng tiện lợi, bạn có thể dễ dàng thanh toán tại các cửa hàng tiện lợi gần bạn. Đây cũng là một dịch vụ tiện lợi cho du khách từ nước ngoài.',
        'Nếu bạn sử dụng eSIM, bạn có thể bắt đầu sử dụng ngay sau khi mua. Cả du khách và cư dân dài hạn đều có thể dễ dàng thiết lập môi trường thông tin liên lạc tại Nhật và kết nối internet ngay khi đến nơi.'
      ],
      campaign: 'Thêm vào đó, chiến dịch hiện tại của chúng tôi cung cấp tháng đầu tiên miễn phí! Có hiệu lực đến cuối tháng sau khi bạn mua. Đừng bỏ lỡ cơ hội này!'
    },

    navigation: {
      mypage: 'Đi đến Trang của Tôi',
      contact: 'Liên hệ với Chúng Tôi',
      purchase: 'Mua hàng',
      contactOptions: {
        line: 'LINE',
        telegram: 'Telegram',
        form: 'Mail'
      }
    },

    footer: {
      copyright: '© 2023 Cửa hàng SIM trả trước Nhật Bản. Bảo lưu mọi quyền.'
    },

    startNow: 'Hãy Bắt đầu ngay',

    faq: {
      title: 'Câu hỏi thường gặp',
      items: [
        {
          question: 'Có cần xác minh danh tính khi mua không?',
          answer: 'Không, không cần xác minh danh tính (eKYC).'
        },
        {
          question: 'Thông tin cá nhân được lưu trữ như thế nào?',
          answer: 'Chúng tôi tôn trọng bảo vệ quyền riêng tư và chỉ lưu trữ thông tin cá nhân tối thiểu. Chỉ lưu trữ địa chỉ email dùng để thanh toán và liên kết PayPay.'
        },
        {
          question: 'SIM trả trước có thời hạn sử dụng không?',
          answer: 'Đối với lần mua đầu tiên, có thể sử dụng đến cuối tháng tiếp theo. Sau đó, có thể gia hạn theo tháng. Hiện tại, trong thời gian khuyến mãi, tháng đầu tiên (tháng mua) được miễn phí.'
        },
        {
          question: 'Tốc độ truyền dữ liệu như thế nào?',
          answer: 'Sử dụng mạng DoCoMo, hỗ trợ 4G/LTE với tốc độ tải xuống tối đa 150Mbps. Tuy nhiên, do đây là dịch vụ tốt nhất có thể, tốc độ thực tế có thể thay đổi tùy thuộc vào điều kiện mạng và tình trạng tắc nghẽn.'
        },
        {
          question: 'Điều gì xảy ra khi tôi sử dụng hết dung lượng dữ liệu?',
          answer: 'Khi bạn sử dụng hết dung lượng dữ liệu, tốc độ sẽ giảm xuống 200Kbps cho đến cuối tháng. Dung lượng dữ liệu sẽ được đặt lại vào ngày 1 của tháng tiếp theo.'
        },
        {
          question: 'Tôi có thể sử dụng SIM này bên ngoài Nhật Bản không?',
          answer: 'Không, hiện tại chỉ có thể sử dụng trong lãnh thổ Nhật Bản.'
        },
        {
          question: 'Tôi có thể sử dụng ở mọi nơi tại Nhật Bản không?',
          answer: 'Có, chỉ cần có vùng phủ sóng mạng DoCoMo, bạn có thể sử dụng ở bất kỳ đâu tại Nhật Bản, bao gồm cả các đảo xa.'
        },
        {
          question: 'Có ràng buộc thời hạn hợp đồng không?',
          answer: 'Không, không có ràng buộc thời hạn hợp đồng. Nếu bạn không gia hạn thời gian sử dụng, bạn có thể bỏ đi.'
        },
        {
          question: 'Có thể gửi tin nhắn SMS không?',
          answer: 'Không, chỉ hỗ trợ nhận SMS. Nếu cố gắng gửi, đường truyền sẽ bị tạm ngưng bởi hệ thống. Nếu đường truyền bị tạm ngưng, bạn có thể khôi phục bằng cách trả phí khôi phục 3,300 yên trong thời gian sử dụng.'
        },
        {
          question: 'Có thể gọi điện thoại không?',
          answer: 'Không, không thể gọi điện thoại. Vui lòng cân nhắc sử dụng các phương thức gọi điện qua dữ liệu như ứng dụng LINE hoặc điện thoại IP 050.'
        },
        {
          question: 'Tại sao tôi không nhận được email thông báo sau khi mua?',
          answer: 'Vui lòng kiểm tra xem có nhập sai địa chỉ email không. Đồng thời, kiểm tra xem email có trong thư mục spam không. Nếu không nhận được thông tin kích hoạt eSIM, vui lòng liên hệ bộ phận hỗ trợ.'
        },
        {
          question: 'Có thể mua số lượng lớn cùng một lúc không?',
          answer: 'Có, vui lòng liên hệ với chúng tôi. Chúng tôi có giá bán sỉ.'
        },
        {
          question: 'Gói "Không có dữ liệu di động" nghĩa là gì?',
          answer: 'Gói Không có dữ liệu di động có nghĩa là bạn chỉ có thể nhận SMS và không thể sử dụng dữ liệu di động. Điều này phù hợp cho những người chỉ muốn duy trì số điện thoại.'
        },
        {
          question: 'Các mẫu EID hỗ trợ là gì?',
          answer: 'Chỉ hỗ trợ bốn mẫu EID sau đây:\n\n- 89049032～\n- 89033024～\n- 89043051～\n- 89043052～\n\nXin lưu ý rằng các EID ngoài những mẫu này không được hỗ trợ.'
        }
      ]
    }
  }
}; 