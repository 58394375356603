import React, { createContext, useState, useEffect } from 'react';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      setLanguage(storedLanguage);
    } else {
      fetch('https://ipapi.co/json/')
        .then(response => response.json())
        .then(data => {
          const countryCode = data.country_code;
          let detectedLanguage = 'en';

          switch (countryCode) {
            case 'JP':
              detectedLanguage = 'ja';
              break;
            case 'CN':
              detectedLanguage = 'zh';
              break;
            case 'VN':
              detectedLanguage = 'vi';
              break;
            default:
              detectedLanguage = 'en';
          }

          setLanguage(detectedLanguage);
          localStorage.setItem('language', detectedLanguage);
        })
        .catch(() => {
          setLanguage('en');
          localStorage.setItem('language', 'en');
        });
    }
  }, []);

  const handleSetLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem('language', lang);
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage: handleSetLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}; 